class YswAsLowAs {
    constructor(context) {
        if (!context.asLowAsEnabled) return;

        this.context = context;
        this.storefrontApiToken = context.storefrontApiToken;
        this.query = /* gql */ `
            query productById($products: [Int!]) {
                site {
                    products(entityIds: $products, first: 50) {
                        edges {
                            node {
                                entityId
                                customFields {
                                    edges {
                                        node {
                                            name
                                            value
                                        }
                                    }
                                }
                                prices {
                                    price {
                                        value
                                        currencyCode
                                    }
                                    bulkPricing {
                                        minimumQuantity
                                        maximumQuantity
                                        ... on BulkPricingFixedPriceDiscount {
                                            price
                                        }
                                        ... on BulkPricingPercentageDiscount {
                                            percentOff
                                        }
                                        ... on BulkPricingRelativePriceDiscount {
                                            priceAdjustment
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `;
        this.init();
    }

    async init() {
        const productsIds = this.getProductsIds();
        const products = await this.getProductsPricesByIds(productsIds);
        products.forEach((product) => {
            const minimunPrice = this.calcMinimunPrice(product.prices).toFixed(2);
            const customName = product.customFields.edges.find((field) => field.node.name === 'as-low-as-name')?.node?.value;
            this.injectPrice(product.entityId, minimunPrice, customName);
        });
    }

    getProductsIds() {
        const products = document.querySelectorAll('[data-ysw-as-low-as]:is(.ysw-c-as-low-as--hidden)');
        return Array.from(products).map((product) => Number(product.getAttribute('data-ysw-as-low-as')));
    }

    async getProductsPricesByIds(ids) {
        const response = await fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.context.storefrontApiToken}`,
            },
            body: JSON.stringify({
                query: this.query,
                variables: {
                    products: ids,
                },
            }),
        });
        const result = await response.json();
        return result.data.site.products.edges.map((edge) => edge.node);
    }

    calcMinimunPrice(prices) {
        if (prices.bulkPricing.length === 0) {
            return prices.price.value;
        }

        const highestDiscountIndex = prices.bulkPricing.findIndex((price) => price.maximumQuantity === null);

        // BulkPricingFixedPriceDiscount
        if (prices.bulkPricing[0].price) {
            return (prices.bulkPricing[highestDiscountIndex] ?? prices.bulkPricing[0]).price
        }

        // BulkPricingPercentageDiscount
        if (prices.bulkPricing[0].percentOff) {
            return prices.price.value - prices.price.value * ((prices.bulkPricing[highestDiscountIndex] ?? prices.bulkPricing[0]).percentOff / 100)
        }

        // BulkPricingRelativePriceDiscount
        if (prices.bulkPricing[0].priceAdjustment) {
            return prices.price.value - (prices.bulkPricing[highestDiscountIndex] ?? prices.bulkPricing[0]).priceAdjustment
        }

        return prices.price.value
    }

    injectPrice(elementId, asLowAsPrice, customName) {
        const elements = document.querySelectorAll(`[data-ysw-as-low-as="${elementId}"]`);

        elements.forEach((element) => {
            element.classList.remove('ysw-c-as-low-as--hidden');
            element.innerText = `As low as $${asLowAsPrice} ${customName ? `${customName}` : 'ea'}`;
        });
    }
}

export default function YswAsLowAsFactory(context) {
    return new YswAsLowAs(context);
}
