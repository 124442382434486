import YswAsLowAsFactory from "../components/as-low-as";

class YswGlobal {
    constructor(context) {
        this.context = context;
        this.init();
    }

    init() {
        YswAsLowAsFactory(this.context);
    }
}

export default function YswGlobalFactory(context) {
    return new YswGlobal(context);
}
